import Header from '../../Header.vue'
import Footer from '../../Footer.vue'
import CheckInService from '../../../api/CheckInService.js'
import LoginPop from '../../Login/LoginPop.vue'
import VIPLayer from '../../../components/VIPLayer.vue'

export default{
	name:'JournalManagerDetail',
	data(){
		return{
			JournalModelInfo:'',//详细信息
			RelationList:[],//相关期刊
			collectionStatus:0,
			showLogin:false
		}
	},
	methods:{
		GetJournalModel(){ //获取期刊详情
			var that = this
			CheckInService.GetJournalModel(that.$route.query.id,that.$UserID).then(res=>{
				console.log(res)
				if(res.Tag == 1){
					if(res.Result.EntryModel.ReleaseState == 1){
						that.JournalModelInfo = res.Result
						that.collectionStatus = res.Result.ComStatus
					}else{
						that.$alert('该资源未发布', '提示', {
							confirmButtonText: '确定',
							callback: action => {
								if (action == "confirm") {
									window.close()
								}
								window.close()
							}
						});
					}
				}
			})
		},
		GetRelationJournal(){ //获取相关期刊
			var that = this
			CheckInService.GetRelationJournal(that.$route.query.id,5).then(res=>{
				if(res.Tag == 1){
					that.RelationList = res.Result
				}
			})
		},
		InsertCollection(){ //添加收藏
			var that = this
			if(that.$UserID){
				CheckInService.InsertUserColletion(that.$route.query.id,that.JournalModelInfo.EntryModel.CycleName,that.$UserID,6).then(res=>{
					if(res.Tag == 1){
						that.collectionStatus = 1
						that.$alert('收藏成功', '提示', {
							confirmButtonText: '确定'
						});
					}else{
						that.$alert(res.Message, '提示', {
							confirmButtonText: '确定'
						});
					}
				})
			}else{
				that.showLogin = false
				that.showLogin = true
			}
		},
		CancelCollection(){ //取消收藏
			var that = this
			if(that.$UserID){
				CheckInService.UpdateUserColletion(that.$UserID,that.$route.query.id).then(res=>{
					if(res.Tag == 1){
						that.collectionStatus = 0
						that.$alert('取消收藏成功', '提示', {
							confirmButtonText: '确定'
						});
					}else{
						that.$alert(res.Message, '提示', {
							confirmButtonText: '确定'
						});
					}
				})
			}else{
				that.showLogin = false
				that.showLogin = true
			}
		},
		toRead(){
			var that = this
			if(that.$UserID){
				if(that.$ServiceType == 1){ //vip
					if(that.JournalModelInfo.EntryModel.PDFPath){
						this.$router.push({
							path:'JournalReadPDF',
							query:{
								id:this.$route.query.id
							}
						})
					}else if(that.JournalModelInfo.EntryModel.EReadPath){
						this.$router.push({
							path:'/JournalReadEpub',
							query:{
								id:this.$route.query.id
							}
						})
					}
					
				}else{
					that.$alert('请联系平台管理员开通VIP服务', '提示', {
						confirmButtonText: '确定'
					});
				}
				
			}else{
				that.showLogin = false
				that.showLogin = true
			}
		},
		toElementDetail(value){ //词条详情
			if(value.type == 1  && value.ReleaseStatus != 0){
				window.open(this.$WebUrl + 'ElementDetail?id=' + value.id)
			}
		},
		toLogin(){
			this.showLogin = false
			this.showLogin = true
		}
	},
	mounted(){
		this.GetJournalModel()
		this.GetRelationJournal()
	},
	components:{
		'Header': Header,
		'Footer':Footer,
		'LoginPop':LoginPop,
		'VIPLayer':VIPLayer
	},
	watch: {
		$route() {
			location.reload()
		}
	}
}